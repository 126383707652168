import React, {useEffect, useState} from 'react';
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import Cookies from 'js-cookie';

import TagManager from 'react-gtm-module'
import {getCookieConsentValue} from "react-cookie-consent";

const GoogleSignInButton = ({deeplink, gamesession, game, coins}) => {
  const [hasAccepted, setHasAccepted] = useState(false);

  useEffect(() => {
    setHasAccepted(getCookieConsentValue('cookieConsent') === "true" || false)
  }, [])
  const handleSuccess = async (response) => {
    const idToken = response.credential;
    try {
      const res = await fetch(`${process.env.API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY
        },
        body: JSON.stringify({token: idToken, gamesession, coins, game}),
      });
      if (res.ok) {
        const data = await res.json();
        await new Promise((resolve) => {
          Cookies.set('c8_session_token', data.token, { expires: 365, secure: false, sameSite: 'strict' });
          resolve();
        });
        if (hasAccepted) {
          TagManager.dataLayer({
            dataLayer: {
              user_id: data.userId,
            },
          });
          Cookies.set('cr8_userId', true);
        }

        if (data.newAccount) {
          if (deeplink===null) {
            window.location.href = "/account_google_newsignup";
          } else {
            window.location.href = "/account_google_newsignup?back="+deeplink;
          }
        } else {
          if (deeplink===null) {
            window.location.href = '/account';
          } else {
            window.location.href = deeplink;
          }
        }
      } else {
        console.log('Failed to authenticate user:', res.statusText);
      }
    } catch (error) {
      console.log('Error sending token to backend:', error);
    }
  };

  const handleFailure = (response) => {
    console.error('Google sign-in error:', response);
  };

  return (
    <GoogleOAuthProvider clientId="770665449976-oplpl4c660b1114r1tivkpom8vq7gugr.apps.googleusercontent.com">
      <GoogleLogin
        theme='filled_blue' 
        onSuccess={handleSuccess}
        onError={handleFailure}
        logo_alignment='left'
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleSignInButton;
